import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, Chip } from '@mui/material';
import { PurchaseHistories, PurchaseHistory, StripeReceipt, AndroidReceipt } from '../../../api/users.interface';

const PLATFORM_DISPLAY: { [key: string]: string } = {
    'ios': 'iOS',
    'android': 'Android',
    'stripe': 'Stripe',
}
export const MEMBERSHIP_LEVELS: { [key: string]: string } = {
    '001': 'Monthly',
    '002': '3-Month',
    '003': 'Yearly',
  };
interface ComponentProps {
    purchaseHistories: PurchaseHistories;
}

type Props = ComponentProps;

class PurchaseHistoriesComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }

    renderReceipt(receipt: StripeReceipt | AndroidReceipt | string) {
        if (typeof receipt === 'string') {
            return <Typography variant="body2" color="textSecondary">Receipt: {receipt}</Typography>;
        } else if ('automatic_tax' in receipt) {
            // Handle StripeReceipt
            return (
                <Box>
                    <Typography variant="body2" color="textSecondary">Stripe Receipt</Typography>
                    <Typography variant="body2" color="textSecondary">Billing: {receipt.billing}</Typography>
                    <Typography variant="body2" color="textSecondary">Currency: {receipt.currency}</Typography>
                    <Typography variant="body2" color="textSecondary">Status: {receipt.status}</Typography>
                </Box>
            );
        } else {
            // Handle AndroidReceipt
            return (
                <Box>
                    <Typography variant="body2" color="textSecondary">Android Receipt</Typography>
                    <Typography variant="body2" color="textSecondary">Order ID: {receipt.orderId}</Typography>
                    <Typography variant="body2" color="textSecondary">Product ID: {receipt.productId}</Typography>
                    <Typography variant="body2" color="textSecondary">Purchase State: {receipt.purchaseState}</Typography>
                </Box>
            );
        }
    }

    renderPurchaseHistory(key: string, history: PurchaseHistory) {
        const platformDisplay = PLATFORM_DISPLAY[history.platform] ?? `Unknown Platform: ${history.platform}`;
        const productDisplay = MEMBERSHIP_LEVELS[history.productID] ?? `Unknown Product ID: ${history.productID}`;
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={`Platform: ${platformDisplay}`}
                        subheader={`Transaction Date: ${this.formatDate(history.transactionDate)}`}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">Product: {productDisplay}</Typography>
                        {history.isDevEnvironment && <Chip label="Dev Environment" color="primary" />}
                        {history.isFromTestflight && <Chip label="From Testflight" color="secondary" />}
                        {this.renderReceipt(history.transactionReceipt)}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { purchaseHistories } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(purchaseHistories).map(([key, history]) => this.renderPurchaseHistory(key, history))}
                </Grid>
            </Box>
        );
    }
}

export default PurchaseHistoriesComponent;
