import { GetStateFunction, DispatchFunction } from "../reducers";
import { getCurrentTrialPeriod as getCurrentTrialPeriodAPI, updateTrialPeriod as updateTrialPeriodAPI } from "../api/misc";
import { addError, addSuccess } from "./notifications";
import { MISC_UPDATE, LOADING_TRIAL_PERIOD } from "./types";


export const getCurrentTrialPeriod = ()  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: LOADING_TRIAL_PERIOD, payload: true });
        const trialPeriod = await getCurrentTrialPeriodAPI();

        dispatch({ type: MISC_UPDATE, payload: { loadingTrialPeriod: false, trialPeriod } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred loading current trial period.'));
        }
        
        dispatch({ type: LOADING_TRIAL_PERIOD, payload: false });
    }
}

export const updateTrialPeriod = (trialPeriod: string | number) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { misc } = getState();
    const { trialPeriod: currentTrialPeriod } = misc;
    dispatch({ type: MISC_UPDATE, payload: { trialPeriod } });
    try {
        if(isNaN(Number(trialPeriod)) || !trialPeriod) {
            dispatch(addError('Please enter a valid number for the trial period.'));
            dispatch({ type: MISC_UPDATE, payload: { trialPeriod: currentTrialPeriod } });
            return;
        }
         await updateTrialPeriodAPI(Number(trialPeriod));

        dispatch({ type: MISC_UPDATE, payload: { trialPeriod } });
        dispatch(addSuccess('Trial period updated successfully.'));
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while loading the user. Please try again.'));
        }
        
    dispatch({ type: MISC_UPDATE, payload: { trialPeriod: currentTrialPeriod } });
    }
}