import axios from 'axios';
import { UserDetails, UserProfile, UserProfileStatistics, WorkoutStreak } from './users.interface';
import { ApiError } from '../common/errors';

export const USER_SEARCH_ENDPOINT = '/api/users/search';
export const USER_ENDPOINT = '/api/users';

export const getUserDetailsByEmailOrUID = async (emailOrUid: string): Promise<UserDetails> => {
    try {
        const { data } = await axios.post(`${USER_SEARCH_ENDPOINT}`, { search: emailOrUid });
        const { item } = data;

        return item as UserDetails;
    } catch (e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};


export const getUserObjectByUid = async (uid: string): Promise<{ user: UserProfile, stats: UserProfileStatistics}> => {
    try {
        const { data } = await axios.get(`${USER_ENDPOINT}/${uid}`);
        const { item, stats } = data;

        return {
            user: item as UserProfile,
            stats: stats as UserProfileStatistics
        };
    } catch (e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};

export const updateWorkoutStreak = async (uid: string, workoutStreak: WorkoutStreak): Promise<void> => {
    try {
        await axios.put(`${USER_ENDPOINT}/${uid}/workout-streak`, workoutStreak);
    } catch (e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
}