import { Component } from 'react';
import { Card, CardContent, Avatar, Typography, Box, Grid, Divider, Tabs, Tab, FormControlLabel, Switch } from '@mui/material';
import { TabPanel, TabTitle } from '../common/Tabs';
import { UserProfile, WorkoutStreak } from '../../api/users.interface';
import AchievementsComponent from './UserProfile/AchievementsComponent';
import ChallengeWorkoutDetailsComponent from './UserProfile/ChallengeWorkoutDetailsComponent';
import CompletedBonusChallengesComponent from './UserProfile/CompletedBonusChallengesComponent';
import FavoriteVideosComponent from './UserProfile/FavoriteVideosComponent';
import PushNotificationsComponent from './UserProfile/PushNotificationsComponent';
import GoalsComponent from './UserProfile/GoalsComponent';
import SelfCareLogsComponent from './UserProfile/SelfCareLogsComponent';
import SpringSlimDownChallengesComponent from './UserProfile/SpringSlimDownChallengesComponent';
import SweatLogsComponent from './UserProfile/SweatLogsComponent';
import WaterBottleLogsComponent from './UserProfile/WaterBottleLogsComponent';
import WeightsComponent from './UserProfile/WeightsComponent';
import PurchaseHistoriesComponent from './UserProfile/PurchaseHistoriesComponent';
import CompletedWorkoutsComponent from './UserProfile/CompletedWorkoutsComponent';
import { ConfirmationDialog } from '../common/Modal';

interface ComponentProps {
    item: UserProfile;
    manager?: boolean;
    self?: boolean;
    updateWorkoutStreak: (uid: string, updatedStreak: WorkoutStreak) => void;
    addError: (message: string) => void;
    addAdmin: (email: string) => void;
    addAdminManager: (email: string) => void;
    removeAdmin: (email: string) => void;
    removeAdminManager: (email: string) => void;
}

interface ComponentState {
    selectedTab: number;
    showConfirmAdminManager: boolean;
    showConfirmAdmin: boolean;
}

class UserProfileContent extends Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            selectedTab: 0,
            showConfirmAdminManager: false,
            showConfirmAdmin: false
        };
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ selectedTab: newValue });
    };

    onUpdatedWorkoutStreak = (updatedStreak: WorkoutStreak) => {
        const { item } = this.props;
        const { uid } = item;
        this.props.updateWorkoutStreak(uid, updatedStreak);
    }

    onAdminManagerToggled = () => {
        const { item } = this.props;
        const { admin, manager, email } = item;
        this.onCloseConfirmationDialog();
        if (!admin) {
            this.props.addError('You cannot add admin manager permission without admin permission. Please add admin permission first.');

            return;
        }

        if (manager) {
            this.props.removeAdminManager(email);
        } else {
            this.props.addAdminManager(email);
        }
    }

    onAdminToggled = () => {
        const { item } = this.props;
        const { admin, manager, email } = item;
        this.onCloseConfirmationDialog();
        if (manager && admin) {
            this.props.addError('You cannot remove admin permission from an admin manager. Please remove admin manager permission first.');

            return;
        }

        if (admin) {
            this.props.removeAdmin(email);
        } else {
            this.props.addAdmin(email);
        }

    }

    onShowConfirmAdminManager = () => {
        this.setState({ showConfirmAdminManager: true });
    }

    onShowConfirmAdmin = () => {
        this.setState({ showConfirmAdmin: true });
    }

    onCloseConfirmationDialog = () => {
        this.setState({ showConfirmAdminManager: false, showConfirmAdmin: false });
    }

    render() {
        const { item: profile } = this.props;
        const { selectedTab } = this.state;

        return (
            <Card sx={{ maxWidth: '90rem', marginBottom: 'auto' }}>
                <CardContent>
                    <Box display="flex" alignItems="center" marginBottom={2}>
                        <ConfirmationDialog
                            id="admin-manager"
                            visible={this.state.showConfirmAdminManager}
                            title="Admin Manager"
                            onClose={this.onCloseConfirmationDialog}
                            onConfirmed={this.onAdminManagerToggled}
                        >
                            Are you sure you want to change the user's admin manager permission?
                        </ConfirmationDialog>
                        <ConfirmationDialog
                            id="admin"
                            visible={this.state.showConfirmAdmin}
                            title="Admin"
                            onClose={this.onCloseConfirmationDialog}
                            onConfirmed={this.onAdminToggled}
                        >
                            Are you sure you want to change the user's admin permission?
                        </ConfirmationDialog>
                        <Avatar src={profile.avatar} alt={profile.username} style={{ width: 60, height: 60 }} />
                        <Box marginLeft={2}>
                            <Typography variant="h5">{profile.username}</Typography>
                            <Typography variant="body2" color="textSecondary">{profile.name} {profile.lastname}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box marginTop={2}>
                        <Typography variant="h6">Personal Information</Typography>
                    </Box>
                    <Divider />
                    <Box marginTop={2}>
                        <Typography variant="h6">Permissions</Typography>
                        {this.props.manager && !this.props.self &&
                            <Box marginTop={2}>
                                <FormControlLabel
                                    control={<Switch checked={profile.manager} onChange={this.onShowConfirmAdminManager} />}
                                    label="Admin Manager"
                                />
                                <FormControlLabel
                                    control={<Switch checked={profile.admin} onChange={this.onShowConfirmAdmin} />}
                                    label="Admin"
                                />
                            </Box>
                        }
                        {(!this.props.manager || this.props.self) &&
                            <Box marginTop={2}>
                                <Typography variant="body2" color="textSecondary">
                                    Admin Manager: {profile.manager ? 'Yes' : 'No'}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Admin: {profile.admin ? 'Yes' : 'No'}
                                </Typography>
                            </Box>
                        }
                    </Box>
                    <Divider style={{ margin: '16px 0' }} />
                    <Tabs
                        value={selectedTab}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab id={`tab-${0}`} label={<Typography>{'Goals'}</Typography>} aria-controls={`tabs-tabpanel-${0}`} />
                        <Tab id={`tab-${1}`} label={<Typography>{'Purchase History'}</Typography>} aria-controls={`tabs-tabpanel-${1}`} />
                        <Tab id={`tab-${2}`} label={<Typography>{'Sweat Logs'}</Typography>} aria-controls={`tabs-tabpanel-${2}`} />
                        <Tab id={`tab-${3}`} label={<Typography>{'Completed Workouts'}</Typography>} aria-controls={`tabs-tabpanel-${3}`} />
                        <Tab id={`tab-${4}`} label={<Typography>{'Achievements'}</Typography>} aria-controls={`tabs-tabpanel-${4}`} />
                        <Tab id={`tab-${5}`} label={<Typography>{'Favorite Videos'}</Typography>} aria-controls={`tabs-tabpanel-${5}`} />
                        <Tab id={`tab-${6}`} label={<Typography>{'Push Notifications'}</Typography>} aria-controls={`tabs-tabpanel-${6}`} />
                        <Tab id={`tab-${7}`} label={<Typography>{'Challenge Workout Details'}</Typography>} aria-controls={`tabs-tabpanel-${7}`} />
                        <Tab id={`tab-${8}`} label={<Typography>{'Completed Bonus Challenges'}</Typography>} aria-controls={`tabs-tabpanel-${8}`} />
                        <Tab id={`tab-${9}`} label={<Typography>{'Self Care Logs'}</Typography>} aria-controls={`tabs-tabpanel-${9}`} />
                        <Tab id={`tab-${10}`} label={<Typography>{'Spring Slim Down Challenges'}</Typography>} aria-controls={`tabs-tabpanel-${10}`} />
                        <Tab id={`tab-${11}`} label={<Typography>{'Water Bottle Logs'}</Typography>} aria-controls={`tabs-tabpanel-${11}`} />
                        <Tab id={`tab-${12}`} label={<Typography>{'Weights'}</Typography>} aria-controls={`tabs-tabpanel-${12}`} />
                    </Tabs>
                    <Box marginTop={2}>
                        <TabPanel value={selectedTab} index={0}>
                            <GoalsComponent goals={profile.goals} updateWorkoutStreak={this.onUpdatedWorkoutStreak}  />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            <PurchaseHistoriesComponent purchaseHistories={profile.purchaseHistory} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={2}>
                            <SweatLogsComponent sweatLogs={profile.sweatLogs} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={3}>
                            <CompletedWorkoutsComponent completedWorkouts={profile.completedWorkouts} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={4}>
                            <AchievementsComponent achievements={profile.achievements} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={5}>
                            <FavoriteVideosComponent favoriteVideos={profile.favoriteVideos} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={6}>
                            <PushNotificationsComponent pushNotifications={profile.pushNotifications} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={7}>
                            <ChallengeWorkoutDetailsComponent challengeWorkoutDetails={profile.challangeWorkoutDetails} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={8}>
                            <CompletedBonusChallengesComponent completedBonusChallenges={profile.completedBonusChallenges} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={9}>
                            <SelfCareLogsComponent selfCareLogs={profile.selfCareLogs} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={10}>
                            <SpringSlimDownChallengesComponent springSlimDownChallenges={profile.springSlimDownChallenges} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={11}>
                            <WaterBottleLogsComponent waterBottleLogs={profile.waterBottleLogs} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={12}>
                            <WeightsComponent weights={profile.weight} />
                        </TabPanel>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}

export default UserProfileContent;
